html, body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
body { line-height: 1; }

table {
  border-collapse: collapse;
  border-spacing: 0;
}

