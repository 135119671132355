*,
*:before,
*:after {
  box-sizing: border-box;
}

@font-face {
  src: url(../public/NotoColorEmoji.ttf);
  font-family: EmojiFont;
}

html,
body {
  width: 100%;
  height: 100%;
  min-width: 960px;
  overflow: hidden;
  font-size: 13px;
  font-family: 'Ubuntu', sans-serif, EmojiFont;
}

#mf-persons-entry {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  /* КОСТЫЛЬ: фикс overflow контента в person card*/
  background: #fff;
}
#mf-persons-entry .personContact--value {
  max-width: 200px;
}

button {
  cursor: pointer;
  font-family: inherit;
  background: none;
  border: none;
  padding: 0;
}

button:focus,
input:focus,
textarea:focus {
  outline: 0;
}

input,
textarea {
  font-family: inherit;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

#app {
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

b {
  font-weight: 500;
}

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar,
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 10px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: transparent;

}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover,
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(0,0,0,0.2);
  border-radius: 100px;
  background-clip: padding-box;
  min-height: 10px; /*Prevent it from getting too small */
  border: 2px solid rgba(0, 0, 0, 0);
  transition: background .2 ease-in-out;
}

a {
  color: #2196f3!important;
}

.no-script {
  width: 100vw;
  height: 100vh;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.wrap {
  display: inline-block;
}

.icon {
  width: 83px;
  height: 61px;
  display: inline-block;
}

.header {
  width: 381px;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 1.31;
  text-align: center;
  font-weight: 600;
}

.DraftEditor-root {
  background-color: white;
  position: relative;
  width: 100%;
  line-height: 1.5;
  height: 100%;
}

.DraftEditor-root .public-DraftEditorPlaceholder-root {
  position: absolute;
  top: 26px;
  left: 28px;
  z-index: 0;
}
.DraftEditor-root .public-DraftEditorPlaceholder-inner {
  color: #8e8e93;
}
.DraftEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.DraftEditor-editorContainer {
  position: relative;
  height: 100%;
}

.public-DraftEditor-content {
  /* max-width: 65vw; */
  /* max-height: 156px; */
  overflow-y: auto;
  padding: 26px 98px 26px 26px;
  height: 100%;
}


.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

body.drag * {
  pointer-events: none;
}

.rc-virtual-list-scrollbar {
  width: 5px!important; 
  background-color: #fff!important;
}

.rc-virtual-list-scrollbar-thumb {
  background: rgba(0,0,0,0.2)!important;
}